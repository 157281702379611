import submenuToggle from './components/submenuToggle'
import ajaxZip from './components/ajaxZip'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    submenuToggle()
    ajaxZip()
  },
  false
);
