/**
 * Ajax zip for contact forms
 */
 const ajaxZip = () => {
  const zip = document.querySelector("#zip");

  if(zip) {
    zip.addEventListener('keyup', function () {
      AjaxZip3.zip2addr('zip1','zip2','addr','addr');
    });
  }
}

export default ajaxZip
