/**
  * Submenu toggle
 */

const submenuToggle = () => {
  let header = document.querySelector('.header')

  header.querySelector('.nav__sp-hamburger').addEventListener('click', function(){
    this.classList.toggle('nav__sp-hamburger--close');
    header.querySelector('.nav__wrapper').classList.toggle('nav__wrapper--active')
  });
  
  let items = document.querySelectorAll('.nav__item');

  for(let i = 0; i < items.length; i++) {
    items[i].addEventListener('click', function(){
      items[i].classList.toggle('nav__item--active');
    });
  }
}

export default submenuToggle